// This is the content if we hit an error state during login
// This follows the foundation.app model of login errors
import React, { FC } from "react";
import ModalSubtitle from "../../../common/modal/modalSubtitle";
import ModalTitle from "../../../common/modal/modalTitle";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../common/button/button";

interface ErrorConnectModalProps {
  title: string;
  subtitle: string;
  close: (...any) => void;
  onRetry?: (e) => void; //Callback for the retry action
}

const ErrorConnectModal: FC<ErrorConnectModalProps> = ({
  title,
  subtitle,
  close,
  onRetry = () => {},
}) => (
  <>
    <ModalTitle>
      <h2>{title}</h2>
      <button onClick={close}>
        <CloseIcon className="fill-current text-white" />
      </button>
    </ModalTitle>
    <div className="flex flex-col space-y-24 mt-16">
      <ModalSubtitle>
        <p className="body1">{subtitle}</p>
      </ModalSubtitle>
      <Button width={"full"} onClick={onRetry}>
        Retry
      </Button>
    </div>
  </>
);

export default ErrorConnectModal;
