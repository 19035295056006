import { AbstractConnector } from "@web3-react/abstract-connector";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { SUPPORTED_WALLETS } from "../../../../constants/wallet";
import WalletOption from "./walletOption";
import ModalTitle from "../../../common/modal/modalTitle";
import ModalSubtitle from "../../../common/modal/modalSubtitle";

interface ConnectModalProps {
  close: (...any) => void;
  onTryActivate: (connector: AbstractConnector | undefined) => void;
}

const ConnectModal: FC<ConnectModalProps> = ({ close, onTryActivate }) => {
  const [metamaskDetect, setMetamaskDetect] = useState(false);

  // Detect Metamask mobile/extension
  const handleEthereum = () => {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      setMetamaskDetect(true);
    } else {
      setMetamaskDetect(false);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });

      setTimeout(handleEthereum, 3000);
    }
  }, []);

  return (
    <>
      <ModalTitle>
        <h2>Connect your wallet</h2>
        <button className="focus:outline-none" onClick={close}>
          <CloseIcon className="fill-current text-white" />
        </button>
      </ModalTitle>
      <div className="flex flex-col space-y-24 mt-16">
        <ModalSubtitle>
          <p className="body1 text-dark-white-64">
            By connecting your wallet you agree to our{" "}
            <a
              href="https://docs.szns.io/legal-and-misc/terms-and-conditions"
              className="text-white hover:underline"
              target="_blank"
            >
              Terms of Service
            </a>{" "}
            and our{" "}
            <a
              href="https://docs.szns.io/legal-and-misc/privacy-policy"
              className="text-white hover:underline"
              target="_blank"
            >
              Privacy Policy
            </a>
          </p>
        </ModalSubtitle>
        <div className="flex flex-col space-y-16">
          {Object.keys(SUPPORTED_WALLETS).map((key, index) => (
            <WalletOption
              wallet={SUPPORTED_WALLETS[key]}
              onClick={() => onTryActivate(SUPPORTED_WALLETS[key].connector)}
              key={index}
              metamaskDetected={metamaskDetect}
            />
          ))}
        </div>
        <p
          className={`${
            !metamaskDetect ? "flex" : "hidden"
          } flex-row body1 justify-center text-dark-white-64`}
        >
          Works with all wallets
        </p>
        <p className="body2 text-center">
          <a
            href="https://ethereum.org/en/wallets/"
            target="_blank"
            className="text-dark-red hover:underline"
          >
            Learn more about Ethereum wallets
          </a>
        </p>
      </div>
    </>
  );
};

export default ConnectModal;
