import React, { useState } from "react";
import { Link } from "gatsby";
import MenuAccountBtn from "./menuAccountBtn";
import MenuNotifications from "./menuNotifications";
import { useAuthentication } from "../../hooks/auth/useLogin";
import { useFeature } from "../../hooks/feature/useFeature";
import MobileMenuNav from "./mobile/mobileMenuNav";
import Loading from "../loading/loading";

const MenuNav = () => {
  const { user, authLoading } = useAuthentication();
  const [farmFeatureEnabled, farmFeatureLoading, farmFeatureError] =
    useFeature("farm");

  return (
    <>
      <div className="hidden md:block">
        <div className="flex flex-row text-dark-white-64 space-x-24">
          <Link
            id="menu-explore"
            to="/explore"
            activeClassName="text-white"
            className="body1 flex items-center hover:text-dark-red hover hover:font-semibold"
          >
            <p>Explore Albums</p>
          </Link>
          <Link
            id="menu-youralbums"
            to="/account/albums"
            partiallyActive={true}
            activeClassName="text-white"
            className="body1 flex flex-row items-center hover:text-dark-red hover hover:font-semibold"
          >
            <p>My Albums</p>
          </Link>
          <Link
            id="menu-farm"
            to="/farm"
            partiallyActive={true}
            activeClassName="text-white"
            className={`body1 flex flex-row items-center ${
              farmFeatureEnabled
                ? "hover:text-dark-red hover hover:font-semibold"
                : "hidden"
            }`}
          >
            <p>Farm</p>
          </Link>
          {authLoading ? (
            <div
              className="bg-transparent"
              style={{ width: "24px", height: "24px" }}
            />
          ) : user ? (
            <div className="flex items-center text-dark-white-64 relative fade-in">
              <MenuNotifications />
            </div>
          ) : (
            <div
              className="bg-transparent"
              style={{ width: "24px", height: "24px" }}
            />
          )}
          <div className="flex items-center">
            <MenuAccountBtn />
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <MobileMenuNav />
      </div>
    </>
  );
};

export default MenuNav;
