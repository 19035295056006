import React, { FC } from "react";
import Modal from "../../../common/modal/modal";
import ModalSubtitle from "../../../common/modal/modalSubtitle";
import ModalTitle from "../../../common/modal/modalTitle";
import CloseIcon from "@mui/icons-material/Close";
import Loading from "../../../loading/loading";

interface PendingNonceModalProps {
  close: (...any) => void;
}

const PendingNonceModal: FC<PendingNonceModalProps> = ({ close }) => (
  <>
    <ModalTitle>
      <h2>Please sign the message in your wallet to continue</h2>
      <button onClick={close}>
        <CloseIcon className="fill-current text-white" />
      </button>
    </ModalTitle>
    <div className="flex flex-col space-y-24 mt-16">
      <ModalSubtitle>
        <p className="body1">
          SZNS uses this signature to verify that you’re the owner of this
          Ethereum address
        </p>
      </ModalSubtitle>
      <div className="mx-auto">
        <Loading size={32} />
      </div>
    </div>
  </>
);

export default PendingNonceModal;
