import React, { useState, useEffect, useRef } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationModalMessages from "./notificationModalMessages";
import { useInterval } from "../../hooks/useInterval";
import Api from "../../services/api";
import { NOTIFICATION_INTERVAL } from "../../constants";

const MenuNotifications = () => {
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false);

  // hook thats sets-up polling for hasUnreadMessages api call
  useInterval(() => {
    const api = new Api();
    api
      .hasUnreadMessages()
      .then((resp) => {
        setHasUnreadMessages(resp.data.hasUnread);
      })
      .catch((error) => {
        console.log(error);
      });
  }, NOTIFICATION_INTERVAL);

  // to detect outside clicks to close modal
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    const api = new Api();
    api
      .hasUnreadMessages()
      .then((resp) => {
        setHasUnreadMessages(resp.data.hasUnread);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div ref={ref}>
      <button
        style={{ WebkitAppearance: "none" }}
        onClick={() => setShowNotifications(!showNotifications)}
      >
        {hasUnreadMessages ? (
          <div className="fade-in">
            <NotificationsIcon className="fill-current text-white relative" />
            <div
              className="rounded-full bg-dark-red absolute sm:bottom-3 md:bottom-5 -right-1"
              style={{ width: "15px", height: "15px" }}
            />
          </div>
        ) : (
          <NotificationsIcon className="fill-current text-dark-white-64 fade-in" />
        )}
      </button>

      {showNotifications ? (
        <NotificationModalMessages
          hideModal={() => setShowNotifications(false)}
          hasUnread={hasUnreadMessages}
        />
      ) : null}
    </div>
  );
};

export default MenuNotifications;
