import React from "react";
import { useAuthentication } from "../../hooks/auth/useLogin";
import Button from "../common/button/button";
import Login from "../wallet/login";
import Dropdown from "../common/dropdown/dropdown";
import MobileIconDropdown from "../common/dropdown/mobileIconDropdown";
import { useLogout } from "../../hooks/auth/useLogout";
import Loading from "../loading/loading";
import { useEnsName } from "../../hooks/web3";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";

const MenuAccountBtn = () => {
  const { isLoggedIn, user, authLoading, web3Ethers } = useAuthentication();
  const [logout, logoutError] = useLogout();

  const ens = useEnsName(user?.uid);

  const shortenEns = (ens) => {
    if (ens && ens.length > 13) {
      return `${ens.slice(0, 5)}...${ens.slice(-8)}`;
    } else {
      return ens;
    }
  };

  const dropdownChoices = [
    { option: "View profile", navigateTo: "/account" },
    { option: "Docs", navigateTo: "https://docs.szns.io/" },
    { option: "Disconnect", onClick: logout },
  ];

  if (authLoading) {
    return (
      <>
        <div className="hidden md:block">
          <Button size={"sm"} width={136}>
            <Loading size={20} />
          </Button>
        </div>
        <div className="md:hidden flex items-center">
          <WalletIcon className="fill-current text-dark-white-64 pulse" />
        </div>
      </>
    );
  } else if (user && isLoggedIn) {
    return (
      <>
        <div className="hidden md:block fade-in">
          <Dropdown
            dropdownTitle={`${
              shortenEns(ens) ||
              `${user.uid.slice(0, 5)}...${user.uid.slice(-4)}`
            }`}
            choices={dropdownChoices}
          />
        </div>
        <div className="md:hidden flex items-center">
          <MobileIconDropdown
            dropdownHeader={`${
              shortenEns(ens) ||
              `${user.uid.slice(0, 5)}...${user.uid.slice(-4)}`
            }`}
            dropdownIcon={<WalletIcon className="fill-current text-white" />}
            choices={dropdownChoices}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="hidden md:block fade-in">
          <Login />
        </div>
        <div className="md:hidden flex items-center">
          <Login mobile={true} />
        </div>
      </>
    );
  }
};

export default MenuAccountBtn;
