import { useEthers } from "@usedapp/core";
import React, { FC, useState, useEffect } from "react";
import Button from "../common/button/button";
import ErrorConnectModal from "./connect/modal/errorConnectModal";
import PendingNonceModal from "./connect/modal/pendingNonceModal";
import ConnectModal from "./connect/modal/connectModal";
import Modal from "../common/modal/modal";
import { useConnectWallet } from "../../hooks/wallet/useConnectWallet";
import { useLogin } from "../../hooks/auth/useLogin";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";

enum LoginState {
  LOGGED_OUT,
  ERROR_CONNECT,
  PENDING_NONCE,
  ERROR_NONCE,
  LOGGED_IN,
}

interface LoginProps {
  size?: "sm" | "md" | "lg";
  width?: number | "full";
  mobile?: boolean;
}

const Login: FC<LoginProps> = ({
  size = "sm",
  width = 136,
  mobile = false,
}) => {
  const { account } = useEthers();
  const [loginState, setLoginState] = useState(LoginState.LOGGED_OUT);
  const [isOpen, setIsOpen] = useState(false);
  const [tryActivation, activateError, isConnected] = useConnectWallet();
  const [login, loginError] = useLogin();

  //Listen for wallet connection errors
  useEffect(() => {
    if (activateError) {
      setLoginState(LoginState.ERROR_CONNECT);
    }
  }, [activateError]);

  //Listen for errors during login (authenticate nonce and login to firebase auth)
  useEffect(() => {
    if (loginError) {
      setLoginState(LoginState.ERROR_NONCE);
    }
  }, [loginError]);

  //When the wallet is connected we always require a sign nonce
  useEffect(() => {
    if (isConnected) {
      setLoginState(LoginState.PENDING_NONCE);
      login(account);
    } else {
      setLoginState(LoginState.LOGGED_OUT);
    }
  }, [isConnected]);

  const retryConnect = () => {
    setLoginState(LoginState.LOGGED_OUT);
  };

  const retryLogin = () => {
    setLoginState(LoginState.PENDING_NONCE);
    login(account);
  };

  return (
    <>
      {!mobile ? (
        <Button onClick={() => setIsOpen(true)} size={size} width={width}>
          Connect Wallet
        </Button>
      ) : (
        <WalletIcon
          className="fill-current text-dark-white-64"
          onClick={() => setIsOpen(true)}
        />
      )}

      {/* We'll conditionally render the appropriate modal content under one modal component here  */}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {loginState === LoginState.LOGGED_OUT && (
          <ConnectModal
            close={() => setIsOpen(false)}
            onTryActivate={tryActivation}
          />
        )}
        {loginState === LoginState.ERROR_CONNECT && (
          <ErrorConnectModal
            title="Account access needed"
            subtitle="Please give your wallet permission to view your accounts."
            close={() => setIsOpen(false)}
            onRetry={retryConnect}
          />
        )}
        {loginState === LoginState.PENDING_NONCE && (
          <PendingNonceModal close={() => setIsOpen(false)} />
        )}
        {loginState === LoginState.ERROR_NONCE && (
          <ErrorConnectModal
            title="Signature rejected"
            subtitle="Please sign the message to verify wallet ownership."
            close={() => setIsOpen(false)}
            onRetry={retryLogin}
          />
        )}
        {loginState === LoginState.LOGGED_IN && null}
      </Modal>
    </>
  );
};

export default Login;
