import { useEffect, useState } from "react";
import Api from "../../services/api";

export const useFeature = (featureFlag: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [featureFlagEnabled, setFeatureFlagEnabled] = useState<boolean>();

  useEffect(() => {
    // const api = new Api();

    // api.getFeatureFlag(featureFlag).then(response => {
    //     setFeatureFlagEnabled(response.data);
    // }).catch(error => {
    //     console.log(error);
    //     setError(error);
    // }).finally(() => {
    //     setLoading(false);
    // })

    if (featureFlag === "farm") {
      setLoading(false);
      setFeatureFlagEnabled(false);
    } else {
      setLoading(false);
      setFeatureFlagEnabled(true);
    }
  }, []);

  return [featureFlagEnabled, loading, error];
};
