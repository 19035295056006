import React from "react";
import { Link } from "gatsby";
import SznsMobile from "../../../images/icons/szns/szns_mobile_logo.png";

const MobileLogo = () => (
  <Link to="/">
    <img src={SznsMobile} />
  </Link>
);

export default MobileLogo;
