import { useDisconnectWallet } from "../wallet/useDisconnectWallet";
import firebase from "gatsby-plugin-firebase";
import { useCallback, useState } from "react";

//Returns callback to sign out of firebase and disconnect our wallet
export const useLogout = (): [() => Promise<void>, any] => {
  const [error, setError] = useState<any>();
  const disconnect = useDisconnectWallet();

  const logout = useCallback(async (): Promise<void> => {
    try {
      await firebase.auth().signOut();
      await disconnect();
    } catch (err) {
      setError(error);
    }
  }, [disconnect]);

  return [logout, error];
};
