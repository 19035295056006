import React, { useState, Fragment } from "react";
import { Link } from "gatsby";
import MenuAccountBtn from "./../menuAccountBtn";
import MenuNotifications from "./../menuNotifications";
import { useAuthentication } from "../../../hooks/auth/useLogin";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Popover, Transition } from "@headlessui/react";
import MobileLogo from "../../common/logo/mobileLogo";
import { useFeature } from "../../../hooks/feature/useFeature";

const MobileMenuNav = () => {
  const { user, authLoading } = useAuthentication();

  const [showMenu, setShowMenu] = useState(false);
  const [farmFeatureEnabled, farmFeatureLoading, farmFeatureError] =
    useFeature("farm");

  return (
    <div className="flex flex-row space-x-24 h-full items-center">
      {!authLoading && user && (
        <div className="flex flex-row items-center text-dark-white-64 relative fade-in">
          <MenuNotifications />
        </div>
      )}
      <MenuAccountBtn />
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className="flex justify-center items-center h-24 w-24 focus:outline-none"
              style={{ WebkitAppearance: "none" }}
            >
              {open ? null : (
                <MenuIcon className="fill-current text-dark-white-64" />
              )}
            </Popover.Button>
            <Popover.Overlay
              className={` ${
                open ? "bg-black bg-opacity-80 fixed inset-0 fade-in" : "hidden"
              }`}
            />
            <Transition
              as={Fragment}
              enter="transition duration-300"
              enterFrom="transform -translate-x-full"
              enterTo="transform translate-x-0"
              leave="transition duration-300"
              leaveFrom="transform translate-x-0"
              leaveTo="transform -translate-x-full"
            >
              <Popover.Panel
                className="fixed left-0 top-0 h-screen bg-dark-background z-20"
                style={{ width: "303px" }}
              >
                <div className="flex flex-col p-48 h-full text-dark-white-64">
                  <div className="mb-64">
                    <MobileLogo />
                  </div>
                  <div className="flex flex-col space-y-32 flex-grow">
                    <Link className="btn-primary" to="/explore">
                      Explore Albums
                    </Link>
                    <Link className="btn-primary" to="/account/albums">
                      My Albums
                    </Link>
                    <Link
                      className={`${
                        farmFeatureEnabled ? "btn-primary" : "hidden"
                      }`}
                      to="/farm"
                    >
                      Farm
                    </Link>
                    <a
                      href="https://docs.szns.io/"
                      target="_blank"
                      className="btn-primary"
                    >
                      Docs
                    </a>
                  </div>
                  <div className="flex flex-row space-x-24 pb-32">
                    <a href="https://discord.gg/nwNQVQSB5u" target="_blank">
                      <svg
                        className="fill-current text-dark-white-64 hover:text-dark-red"
                        width="21"
                        height="24"
                        viewBox="0 0 21 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18.54 0C19.896 0 21 1.104 21 2.472V24L18.42 21.72L16.968 20.376L15.432 18.948L16.068 21.168H2.46C1.104 21.168 0 20.064 0 18.696V2.472C0 1.104 1.104 0 2.46 0H18.54V0ZM13.908 15.672C16.56 15.588 17.58 13.848 17.58 13.848C17.58 9.984 15.852 6.852 15.852 6.852C14.124 5.556 12.48 5.592 12.48 5.592L12.312 5.784C14.352 6.408 15.3 7.308 15.3 7.308C14.052 6.624 12.828 6.288 11.688 6.156C10.824 6.06 9.996 6.084 9.264 6.18L9.06 6.204C8.64 6.24 7.62 6.396 6.336 6.96C5.892 7.164 5.628 7.308 5.628 7.308C5.628 7.308 6.624 6.36 8.784 5.736L8.664 5.592C8.664 5.592 7.02 5.556 5.292 6.852C5.292 6.852 3.564 9.984 3.564 13.848C3.564 13.848 4.572 15.588 7.224 15.672C7.224 15.672 7.668 15.132 8.028 14.676C6.504 14.22 5.928 13.26 5.928 13.26L6.264 13.464L6.312 13.5L6.359 13.527L6.373 13.533L6.42 13.56C6.72 13.728 7.02 13.86 7.296 13.968C7.788 14.16 8.376 14.352 9.06 14.484C9.96 14.652 11.016 14.712 12.168 14.496C12.732 14.4 13.308 14.232 13.908 13.98C14.328 13.824 14.796 13.596 15.288 13.272C15.288 13.272 14.688 14.256 13.116 14.7C13.476 15.156 13.908 15.672 13.908 15.672ZM8.328 10.068C7.644 10.068 7.104 10.668 7.104 11.4C7.104 12.132 7.656 12.732 8.328 12.732C9.012 12.732 9.552 12.132 9.552 11.4C9.564 10.668 9.012 10.068 8.328 10.068ZM12.708 10.068C12.024 10.068 11.484 10.668 11.484 11.4C11.484 12.132 12.036 12.732 12.708 12.732C13.392 12.732 13.932 12.132 13.932 11.4C13.932 10.668 13.392 10.068 12.708 10.068Z"
                          fill="white"
                          fillOpacity="0.64"
                        />
                      </svg>
                    </a>
                    <a href="https://twitter.com/sznsNFT" target="_blank">
                      <svg
                        className="fill-current text-dark-white-64 hover:text-dark-red"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z" />
                      </svg>
                    </a>
                    <a href="https://szns.medium.com/" target="_blank">
                      <svg
                        className="fill-current text-dark-white-64 hover:text-dark-red"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fillRule="evenodd"
                        clipRule="evenodd"
                      >
                        <path d="M12 0c-6.626 0-12 5.372-12 12 0 6.627 5.374 12 12 12 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12zm6.974 17.557v-.235l-1.092-1.072c-.096-.073-.144-.194-.124-.313v-7.874c-.02-.119.028-.24.124-.313l1.118-1.072v-.235h-3.869l-2.758 6.88-3.138-6.88h-4.059v.235l1.308 1.575c.128.115.194.285.176.457v6.188c.038.223-.032.451-.189.614l-1.471 1.784v.235h4.17v-.235l-1.471-1.784c-.158-.163-.233-.389-.202-.614v-5.352l3.66 7.985h.425l3.143-7.985v6.365c0 .17 0 .202-.111.313l-1.13 1.098v.235h5.49z" />
                      </svg>
                    </a>
                  </div>
                  <div className="flex flex-row justify-start">
                    <p className="caption text-dark-white-64">
                      NFTree Labs, Inc. © 2021
                    </p>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default MobileMenuNav;
