import React, { useState } from "react";
import MainLogo from "../logo/MainLogo";
import Button from "../common/button/button";
import TextInput from "../common/form/input/textInput";
import { isEmailValid } from "../../utils/utils";
import Api from "../../services/api";

const Footer = () => {
  const [email, setEmail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [subscribed, setSubscribed] = useState<boolean>(false);

  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const verifyEmail = (e) => {
    if (e.target) {
      const isValid = isEmailValid(e.target.value);
      if (!isValid) {
        setErrorMsg("Please enter a valid email address");
      } else {
        setErrorMsg("");
      }
    }
  };

  const subscribeNewsletter = () => {
    const api = new Api();
    api
      .subscribeNewsletter(email)
      .then((resp) => {
        setSubscribed(true);
      })
      .catch((error) => {
        console.log(error);
        // Duplicate email calls will fail - but still setting subscribed to as true
        setSubscribed(true);
      });
  };

  return (
    <div className="flex flex-col w-full">
      {/* Column from sm-med Row from lg and up  */}
      <div className="flex flex-col md:flex-row w-full">
        {/* Main logo, Newsletter, Social Media section */}
        <div className="flex flex-col md:flex-row w-full flex-grow lg:space-x-48 xlg:space-x-96">
          <div className="flex sm:flex-col lg:flex-row w-full">
            <div className="flex justify-start pt-32 md:pt-0 pb-32 lg:pb-0 w-full lg:w-1/3 xlg:w-1/4">
              <div style={{ width: "136.67px", height: "40px" }}>
                <MainLogo gradientName="footer" />
              </div>
            </div>

            <div className="flex flex-col text-dark-white-64 space-y-16 lg:space-y-24 w-full md:w-4/5 lg:w-1/2 xlg:w-2/5">
              <h2 className="text-left">Newsletter</h2>
              {subscribed ? (
                <div className="body1 pb-36">
                  Welcome! You'll receive SZNS updates in your inbox at {email}.
                </div>
              ) : (
                <>
                  <TextInput
                    placeholder={"Enter your email"}
                    value={email}
                    error={errorMsg}
                    onChange={handleChange}
                    onBlur={verifyEmail}
                  />
                  <Button
                    disabled={!isEmailValid(email)}
                    onClick={subscribeNewsletter}
                    width={"full"}
                  >
                    Subscribe
                  </Button>
                </>
              )}
              {/* Social Media Links */}
              <div className="flex flex-row justify-start pt-24 space-x-24 sm:pb-48 md:pb-0">
                <a href="https://discord.gg/nwNQVQSB5u" target="_blank">
                  <svg
                    width="21"
                    height="24"
                    viewBox="0 0 21 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.54 0C19.896 0 21 1.104 21 2.472V24L18.42 21.72L16.968 20.376L15.432 18.948L16.068 21.168H2.46003C1.10403 21.168 3.05176e-05 20.064 3.05176e-05 18.696V2.472C3.05176e-05 1.104 1.10403 0 2.46003 0H18.54V0ZM13.908 15.672C16.56 15.588 17.58 13.848 17.58 13.848C17.58 9.984 15.852 6.852 15.852 6.852C14.124 5.556 12.48 5.592 12.48 5.592L12.312 5.784C14.352 6.408 15.3 7.308 15.3 7.308C14.052 6.624 12.828 6.288 11.688 6.156C10.824 6.06 9.99603 6.084 9.26403 6.18L9.06003 6.204C8.64003 6.24 7.62003 6.396 6.33603 6.96C5.89203 7.164 5.62803 7.308 5.62803 7.308C5.62803 7.308 6.62403 6.36 8.78403 5.736L8.66403 5.592C8.66403 5.592 7.02003 5.556 5.29203 6.852C5.29203 6.852 3.56403 9.984 3.56403 13.848C3.56403 13.848 4.57203 15.588 7.22403 15.672C7.22403 15.672 7.66803 15.132 8.02803 14.676C6.50403 14.22 5.92803 13.26 5.92803 13.26L6.26403 13.464L6.31203 13.5L6.35903 13.527L6.37303 13.533L6.42003 13.56C6.72003 13.728 7.02003 13.86 7.29603 13.968C7.78803 14.16 8.37603 14.352 9.06003 14.484C9.96003 14.652 11.016 14.712 12.168 14.496C12.732 14.4 13.308 14.232 13.908 13.98C14.328 13.824 14.796 13.596 15.288 13.272C15.288 13.272 14.688 14.256 13.116 14.7C13.476 15.156 13.908 15.672 13.908 15.672ZM8.32803 10.068C7.64403 10.068 7.10403 10.668 7.10403 11.4C7.10403 12.132 7.65603 12.732 8.32803 12.732C9.01203 12.732 9.55203 12.132 9.55203 11.4C9.56403 10.668 9.01203 10.068 8.32803 10.068ZM12.708 10.068C12.024 10.068 11.484 10.668 11.484 11.4C11.484 12.132 12.036 12.732 12.708 12.732C13.392 12.732 13.932 12.132 13.932 11.4C13.932 10.668 13.392 10.068 12.708 10.068Z"
                      fill="white"
                      fillOpacity="0.64"
                    />
                  </svg>
                </a>
                <a href="https://twitter.com/sznsNFT" target="_blank">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 0C5.37303 0 3.05176e-05 5.373 3.05176e-05 12C3.05176e-05 18.627 5.37303 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18.066 9.645C18.249 13.685 15.236 18.189 9.90203 18.189C8.28003 18.189 6.77103 17.713 5.50003 16.898C7.02403 17.078 8.54503 16.654 9.75203 15.709C8.49603 15.686 7.43503 14.855 7.06803 13.714C7.51903 13.8 7.96303 13.775 8.36603 13.665C6.98503 13.387 6.03103 12.143 6.06203 10.812C6.45003 11.027 6.89203 11.156 7.36303 11.171C6.08403 10.316 5.72203 8.627 6.47403 7.336C7.89003 9.074 10.007 10.217 12.394 10.337C11.975 8.541 13.338 6.81 15.193 6.81C16.018 6.81 16.765 7.159 17.289 7.717C17.943 7.589 18.559 7.349 19.113 7.02C18.898 7.691 18.443 8.253 17.85 8.609C18.431 8.539 18.985 8.385 19.499 8.156C19.115 8.734 18.629 9.24 18.066 9.645Z"
                      fill="white"
                      fillOpacity="0.64"
                    />
                  </svg>
                </a>
                <a href="https://szns.medium.com/" target="_blank">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 0C5.37403 0 3.05176e-05 5.372 3.05176e-05 12C3.05176e-05 18.627 5.37403 24 12 24C18.627 24 24 18.627 24 12C24 5.372 18.627 0 12 0ZM18.974 17.557V17.322L17.882 16.25C17.786 16.177 17.738 16.056 17.758 15.937V8.063C17.738 7.944 17.786 7.823 17.882 7.75L19 6.678V6.443H15.131L12.373 13.323L9.23503 6.443H5.17603V6.678L6.48403 8.253C6.61203 8.368 6.67803 8.538 6.66003 8.71V14.898C6.69803 15.121 6.62803 15.349 6.47103 15.512L5.00003 17.296V17.531H9.17003V17.296L7.69903 15.512C7.54103 15.349 7.46603 15.123 7.49703 14.898V9.546L11.157 17.531H11.582L14.725 9.546V15.911C14.725 16.081 14.725 16.113 14.614 16.224L13.484 17.322V17.557H18.974Z"
                      fill="white"
                      fillOpacity="0.64"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* PRODUCT & INFO links */}
        <div className="flex flex-row sm:justify-between md:justify-evenly sm:w-3/4 w-full lg:w-1/2">
          {/* links */}
          <div className="flex flex-col text-left">
            {/* Product */}
            <h2 className="mb-24 text-dark-white-64">Product</h2>
            <div className="inline-flex flex-col space-y-16 text-dark-white-64">
              <a
                href="https://docs.szns.io/core-components/albums"
                target="_blank"
                className="body1 hover:text-dark-red"
              >
                NFT albums
              </a>
              <a
                href="#TODO"
                target="_blank"
                className="hidden body1 hover:text-dark-red"
              >
                Minting
              </a>
              <a
                href="#TODO"
                target="_blank"
                className="hidden body1 hover:text-dark-red"
              >
                SZNS tokens
              </a>
              <a
                href="https://app.gitbook.com/@szns/s/szns/tutorials/szns-liquidity-mining"
                target="_blank"
                className="hidden body1 hover:text-dark-red"
              >
                Yield Farm
              </a>
              <a
                href="https://docs.szns.io/core-components/governance"
                target="_blank"
                className="body1 hover:text-dark-red"
              >
                sznsDAO
              </a>
            </div>
          </div>
          <div className="flex flex-col text-left">
            {/* Info */}
            <h2 className="mb-24 text-dark-white-64">Info</h2>
            <div className="inline-flex flex-col space-y-16 text-dark-white-64">
              <a
                className="body1 hover:text-dark-red"
                href="https://boards.greenhouse.io/szns"
                target="_blank"
              >
                Join us
              </a>
              <a
                id="footer-litepaper"
                href="https://storage.googleapis.com/szns-landingpage/%5BSZNS%5DLitepaper_20210614.pdf"
                target="_blank"
                className="body1 hover:text-dark-red"
              >
                Litepaper
              </a>
              <a
                id="footer-brandkit"
                href="https://docs.szns.io/branding-kit"
                target="_blank"
                className="body1 hover:text-dark-red"
              >
                Brand Kit
              </a>
              <a
                href="https://docs.szns.io/"
                target="_blank"
                className="body1 hover:text-dark-red"
              >
                Help
              </a>
              <a
                href="https://docs.szns.io/core-components/governance"
                target="_blank"
                className="hidden body1 hover:text-dark-red"
              >
                Governance
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* divider */}
      <div
        className="flex flex-row w-full bg-primary-dark-grey mt-48"
        style={{ height: "1px" }}
      />
      {/* divider */}
      <div className="flex flex-col md:flex-row w-full sm:pt-24 pt-32">
        <div className="flex flex-row flex-grow justify-start pb-16 lg:pb-0">
          <div className="flex flex-row text-dark-white-64 sm:flex-wrap">
            <a
              className="caption mr-16 mb-16 md:mb-0"
              href="https://requests.szns.io/"
              target="_blank"
            >
              Feature Request
            </a>
            <a
              className="caption mr-16 mb-16 md:mb-0"
              href="https://docs.szns.io/legal-and-misc/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
            <a
              className="caption mr-16 mb-16 md:mb-0"
              href="https://docs.szns.io/legal-and-misc/terms-and-conditions"
              target="_blank"
            >
              Terms &amp; Conditions
            </a>
            <a
              className="caption mr-16 mb-16 md:mb-0"
              href="https://docs.szns.io/legal-and-misc/cookies"
              target="_blank"
            >
              Cookies
            </a>
          </div>
        </div>
        <div className="flex flex-row justify-start pb-16 md:pb-0">
          <p className="caption text-dark-white-64">NFTree Labs, Inc. © 2021</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
