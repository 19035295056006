import React, { FC } from "react";
import Input from "./input";
import CSS from "csstype";

interface TextInputProps {
  label?: string;
  error?: string;
  onChange?: (e) => void;
  onKeyDown?: (event) => void;
  onBlur?: () => void;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  width?: number | "full";
  maxLength?: number;
}

const TextInput: FC<TextInputProps> = ({
  label,
  error,
  onChange = (e) => {},
  onKeyDown = (event) => {},
  onBlur = () => {},
  disabled = false,
  placeholder,
  value,
  width,
  maxLength,
}) => {
  //Dyanamic styles for the button
  const styles: CSS.Properties = {};
  let classes;

  if (error) {
    classes = "border-primary-red";
  } else {
    classes = "border-white border-opacity-64";
  }

  if (width) {
    if (width === "full") {
      styles.width = "100%";
    } else {
      styles.width = `${width}px`;
    }
  }

  return (
    <div className="flex flex-col space-y-8" style={{ ...styles }}>
      <Input label={label} error={error}>
        <input
          type="text"
          className={`${classes} bg-transparent py-12 pl-24 pr-24 body2 rounded-xxl h-44 border-2 placeholder-white placeholder-opacity-50 text-white focus:ring-none focus:border-current focus:outline-none w-full hover:bg-white hover:bg-opacity-20 hover:border-current`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={(event) => onKeyDown(event)}
          disabled={disabled}
          onBlur={onBlur}
          maxLength={maxLength}
        />
      </Input>
    </div>
  );
};

export default TextInput;
