import { useCallback, useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { UnsupportedChainIdError } from "@web3-react/core";

//Taken from https://github.com/Uniswap/uniswap-interface/blob/a3aa6647e7a5f5fe93fc29bd258c1248986d8d1b/src/components/WalletModal/index.tsx#L162
//This is the logic for connecting to whatever wallet we are trying to connect
export const useConnectWallet = (): [
  (connector: AbstractConnector | undefined) => Promise<void>,
  any,
  boolean
] => {
  const { activate } = useEthers();
  const [error, setError] = useState();
  const [isConnected, setIsConnected] = useState(false);

  const tryActivation = useCallback(
    async (connector: AbstractConnector | undefined): Promise<void> => {
      if (connector) {
        // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
        //Reference:  https://github.com/NoahZinsmeister/web3-react/issues/124
        if (
          connector instanceof WalletConnectConnector &&
          connector.walletConnectProvider?.wc?.uri
        ) {
          connector.walletConnectProvider = undefined;
        }

        try {
          await activate(connector, undefined, true);
          setIsConnected(true);
        } catch (err1) {
          try {
            if (err1 instanceof UnsupportedChainIdError) {
              await activate(connector); // a little janky...can't use setError because the connector isn't set
              setIsConnected(true);
            } else {
              setError(err1);
            }
          } catch (err2) {
            setError(err2);
          }
        }
      }
    },
    [activate]
  );

  return [tryActivation, error, isConnected];
};
