import { AbstractConnector } from "@web3-react/abstract-connector";
import METAMASK_ICON_URL from "../images/icons/wallet/metamask-fox.svg";
import WALLETCONNECT_ICON_URL from "../images/icons/wallet/walletconnect-circle-blue.svg";
import { injected, walletconnect } from "../web3/connectors";

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconURL: string;
  description: string;
  href: string | null;
  color: {
    background: string;
  };
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: injected,
    name: "MetaMask",
    iconURL: METAMASK_ICON_URL,
    description: "Easy-to-use browser extension.",
    href: null,
    color: {
      background: "linear-gradient(270deg, #FF5F00 0%, #FFDA22 100%)",
    },
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: "WalletConnect",
    iconURL: WALLETCONNECT_ICON_URL,
    description: "Connect to Trust Wallet, Rainbow Wallet and more...",
    href: null,
    color: {
      background: "linear-gradient(90deg, #0900FE 0%, #8F00FF 100%)",
    },
    mobile: true,
  },
};
