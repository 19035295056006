import { Menu, Transition } from "@headlessui/react";
import Button from "../button/button";
import React, { FC, Fragment } from "react";
import { navigate } from "gatsby";

interface DropdownProps {
  dropdownTitle: string;
  choices: Choices[];
}

interface Choices {
  option: string;
  navigateTo?: string;
  onClick?: () => void;
}

const Dropdown: FC<DropdownProps> = ({ dropdownTitle, choices }) => {
  return (
    <div className="flex flex-row z-50">
      <Menu as="div" className="relative block text-left">
        <div>
          <Menu.Button as="div">
            <Button width={136} size="sm">
              {dropdownTitle}
            </Button>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute left-0 mt-16 origin-top-left rounded-sm focus:outline-none"
            style={{
              zIndex: 50,
              width: "136px",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
              background:
                "linear-gradient(270deg, #FF4041 0%, #FF6190 29.69%, #FF8B4B 57.81%, #85D6F7 100%)",
            }}
          >
            <div
              className="bg-black space-y-16 py-24 rounded-sm"
              style={{ margin: "1px" }}
            >
              {choices.map((choice) => (
                <div key={choice.option}>
                  <Menu.Item>
                    {({ active }) =>
                      choice.option === "Docs" ? (
                        <a href={choice.navigateTo} target="_blank">
                          <button
                            className={`${
                              active ? "text-dark-red" : "text-dark-light-grey"
                            } body1 group flex rounded-md items-center w-full pl-24`}
                          >
                            {choice.option}
                          </button>
                        </a>
                      ) : (
                        <button
                          className={`${
                            active ? "text-dark-red" : "text-dark-light-grey"
                          } body1 group flex rounded-md items-center w-full pl-24`}
                          onClick={
                            choice.onClick
                              ? choice.onClick
                              : () => navigate(choice.navigateTo)
                          }
                        >
                          {choice.option}
                        </button>
                      )
                    }
                  </Menu.Item>
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default Dropdown;
