import { useCallback, useEffect, useState } from "react";
import Api from "../../services/api";
import { useSignMsg } from "../wallet/useSignMsg";
import firebase from "gatsby-plugin-firebase";
import useAuthState from "./useAuthState";
import { useEthers, Web3Ethers } from "@usedapp/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useLogout } from "./useLogout";
import { useDispatch } from "react-redux";
import { updateShowFirstLogin } from "../../state/profile/actions";
import { INFURA_KEY } from "../../constants";

//Returns callback to authenticate the nonce signature
//If authenticated, returns a jwt
export const useAuthenticateSignature = (): ((
  address: string,
  signature: string
) => Promise<string>) => {
  const authenticate = useCallback(
    async (address: string, signature: string): Promise<string> => {
      const api = new Api();

      const response = await api.authenticate(address, signature);

      return response.data.jwt;
    },
    []
  );

  return authenticate;
};

//Full login flow for wallet connection
export const useLogin = (): [(address: string) => Promise<void>, any] => {
  const dispatch = useDispatch();
  const signMsg = useSignMsg();
  const authenticate = useAuthenticateSignature();
  const [error, setError] = useState<any>();

  const login = useCallback(
    async (address: string): Promise<void> => {
      try {
        //Get user data with nonce
        const user = await new Api().getUser(address);
        const nonce = user.nonce;

        //Sign the nonce
        const signature = await signMsg(
          `I am signing this nonce: ${nonce} to prove ownership.`
        );

        //Authenticate the signature and get JWT
        const jwt = await authenticate(address, signature);

        //Sign into firebase with the JWT
        firebase
          .auth()
          .signInWithCustomToken(jwt)
          .then((resp) => {
            if (user.isDefault) {
              dispatch(updateShowFirstLogin(true));
            }
          });
      } catch (error) {
        setError(error);
      }
    },
    [signMsg, authenticate]
  );

  return [login, error];
};

//This is the hook that should be consumed by most pages
export const useAuthentication = (): {
  isLoggedIn: boolean;
  user: firebase.User;
  web3Ethers: Web3Ethers;
  authLoading: boolean;
  error: any;
} => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();

  const [logout] = useLogout();

  const [user, authLoading] = useAuthState(firebase);
  const web3Ethers = useEthers();
  useEffect(() => {
    //Wait for firebase auth to return something
    if (!authLoading) {
      //The below if is to handle a logged in user that used wallet connect
      //useDapp eagerly connects to Metamask, but if the user used wallet connect the wallet addr is not correct
      if (
        user &&
        web3Ethers.active &&
        web3Ethers.account && //account only exists when Metamask is unlocked
        web3Ethers.account !== user.uid
      ) {
        (async () => {
          try {
            //Create a WalletConnectProvider
            const provider = new WalletConnectProvider({
              infuraId: `${INFURA_KEY}`, // required
              qrcode: false, // disable qrcode modal
            });

            //Get the connector but don't activate because the user could have disconnected their wallet from our site in native wallet application
            const connector = await provider.getWalletConnector();
            //Get all approved connected addresses and if found, we activate the wallet
            const isWalletApproved = connector.accounts.find(
              (addr) => addr === user.uid
            );
            if (isWalletApproved && provider.connected) {
              await web3Ethers.activate(
                new WalletConnectConnector({
                  connector: connector,
                  infuraId: `${INFURA_KEY}`,
                  chainId: web3Ethers.chainId,
                })
              );
              setIsLoggedIn(true);
            } else {
              setIsLoggedIn(false);
              //Make sure to signout from firebase since the wallet has been disconnected
              firebase.auth().signOut();
            }
          } catch (error) {
            console.log(error);
            setError(error);
          } finally {
            setLoading(false);
          }
        })();
      } else if (user && web3Ethers.account) {
        //Metamask logged in and wallet connected
        setIsLoggedIn(true);
        setLoading(false);
      } else {
        //If no firebase auth instance or no user session on client
        //Logout in case theres a residual token
        try {
          logout(); //In case
        } catch (error) {
          console.log(error);
        }

        setIsLoggedIn(false);
        setLoading(false);
      }
    }
  }, [user]);

  //Just logout if wallet changed and make user log back in
  useEffect(() => {
    if (user && web3Ethers.account !== user.uid) {
      logout();
      setLoading(false);
    }
  }, [web3Ethers.account]);

  return {
    isLoggedIn: isLoggedIn,
    user: user,
    web3Ethers: web3Ethers,
    authLoading: loading,
    error: error,
  };
};
