import { Menu, Transition } from "@headlessui/react";
import Button from "../button/button";
import React, { FC, Fragment } from "react";
import { navigate } from "gatsby";

interface MobileIconDropdownProps {
  dropdownHeader?: string;
  dropdownIcon: React.ReactNode;
  choices: Choices[];
}

interface Choices {
  option: string;
  navigateTo?: string;
  onClick?: () => void;
}

const MobileIconDropdown: FC<MobileIconDropdownProps> = ({
  dropdownHeader,
  dropdownIcon,
  choices,
}) => {
  return (
    <div className="flex flex-row z-15">
      <Menu as="div" className="relative block text-left">
        <Menu.Button
          as="div"
          className="flex items-center"
          style={{ WebkitAppearance: "none" }}
        >
          <div>{dropdownIcon}</div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute -right-38 mt-16 rounded-sm focus:outline-none"
            style={{
              zIndex: 50,
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
              background:
                "linear-gradient(270deg, #FF4041 0%, #FF6190 29.69%, #FF8B4B 57.81%, #85D6F7 100%)",
              minWidth: "134px",
            }}
          >
            <div
              className="bg-black space-y-16 pt-16 pb-20 rounded-sm"
              style={{ margin: "1px" }}
            >
              {dropdownHeader && (
                <p className="responsive-h3 text-dark-light-grey px-16">
                  {dropdownHeader}
                </p>
              )}
              {choices.map((choice) => (
                <div key={choice.option}>
                  <Menu.Item>
                    {({ active }) =>
                      choice.option === "Docs" ? (
                        <a href={choice.navigateTo} target="_blank">
                          <button
                            className={`${
                              active ? "text-dark-red" : "text-dark-light-grey"
                            } btn-second group flex rounded-md items-center w-full pl-16`}
                          >
                            {choice.option}
                          </button>
                        </a>
                      ) : (
                        <button
                          className={`${
                            active ? "text-dark-red" : "text-dark-light-grey"
                          } btn-second group flex rounded-md items-center w-full pl-16`}
                          onClick={
                            choice.onClick
                              ? choice.onClick
                              : () => navigate(choice.navigateTo)
                          }
                        >
                          {choice.option}
                        </button>
                      )
                    }
                  </Menu.Item>
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default MobileIconDropdown;
