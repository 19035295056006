import React, { FC } from "react";
import { WalletInfo } from "../../../../constants/wallet";

interface WalletOptionProps {
  wallet: WalletInfo;
  onClick: (...any) => void;
  metamaskDetected: boolean;
}

const WalletOption: FC<WalletOptionProps> = ({
  wallet,
  onClick,
  metamaskDetected = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${
        wallet.name === "MetaMask"
          ? metamaskDetected
            ? "flex"
            : "hidden md:flex"
          : "flex"
      } flex-row h-48 px-16 py-12 justify-center space-x-10 rounded-xxl btn-base w-full hover:opacity-90`}
      style={{ ...wallet.color }}
    >
      <img src={wallet.iconURL} className="h-24 w-24" />
      <p className="btn-primary">{wallet.name}</p>
    </button>
  );
};

export default WalletOption;
