//Inspiration: https://medium.com/swlh/react-api-call-best-practice-c78e14a7e150

import axios, { AxiosInstance, AxiosResponse } from "axios";
import { API_URL } from "../constants";
import {
  GetProfileResponse,
  SocialMediaLink,
  NFTExport,
  BountyValidatorExport,
  GetNFTsForAddressResponse,
  GetOpenSeaSellOrderDataResponse,
} from "../models/exports";

import firebase from "gatsby-plugin-firebase";
import { UpdateUserRequest, User } from "../models/user";
import axiosRetry from "axios-retry";
import { Order } from "opensea-js/lib/types";
import { ListNFTProposal } from "models/standardProposals/list/listNFTProposal";

export default class Api {
  client: AxiosInstance;

  constructor() {
    this.client = null;
  }

  init = (): AxiosInstance => {
    let headers = {
      Accept: "application/json",
    };

    this.client = axios.create({
      baseURL: API_URL,
      timeout: 31000,
      headers: headers,
    });

    //Add retries to 5xx or 429 status code in response
    //429 is for when opensea throttles requests
    axiosRetry(this.client, {
      retries: 3,
      retryCondition: (error) => {
        return Boolean(
          axiosRetry.isNetworkOrIdempotentRequestError(error) ||
            error.response.status === 429
        );
      },
      retryDelay: (retryCount) => {
        return axiosRetry.exponentialDelay(retryCount);
      },
    });

    return this.client;
  };

  getPostAuthHeader = async (): Promise<any> => {
    try {
      const response = await firebase.auth().currentUser.getIdToken();
      const authObj = {
        Authorization: `Bearer ${response}`,
      };
      return authObj;
    } catch (err) {
      throw err;
    }
  };

  getTest = (params: any): Promise<AxiosInstance> => {
    return this.init().get(`/axies/${params.ID}`);
  };

  getAllAlbums = (): Promise<any> => {
    return this.init().get(`/allAlbums`);
  };

  getFeaturedFunds = (): Promise<any> => {
    return this.init().get(`/sponsored_albums`);
  };

  getFeaturedAlbum = (): Promise<any> => {
    return this.init().get(`/sponsored_album`);
  };

  getTrendingFunds = (): Promise<any> => {
    return this.init().get(`/trending_albums`);
  };

  getFundDetails = ({ id }: { id: string }): Promise<any> => {
    return this.init().get(`/album_details/${id}`);
  };

  getOwnedFunds = (address: string, skip: number): Promise<any> => {
    return this.init().post(`/albums`, {
      address: address,
      skip: skip,
    });
  };

  getNFT = async (id: string, contract: string) => {
    return this.init().post(`/getNFT`, {
      id: id,
      contract: contract,
    });
  };

  getWalletNfts = async ({
    address,
    cursor,
  }: {
    address: string;
    cursor: string;
  }): Promise<AxiosResponse<GetNFTsForAddressResponse>> => {
    return this.init().post(`/nfts/${address}`, {
      cursor: cursor,
    });
  };

  getFractionalNFTs = (address: string): Promise<any> => {
    return this.init().post(`/getFractionalNFTs`, {
      address,
    });
  };

  //Example of how to call and add auth ID token from Firebase auth
  getFundsWithToken = async (): Promise<any> => {
    const response = await firebase.auth().currentUser.getIdToken();
    return await this.init().get(`/albums`, {
      params: { auth: response, skip: 0 },
    });
  };

  createProfile = async (
    name: string,
    username: string,
    address: string,
    links: SocialMediaLink[],
    profilePicture?: Blob | undefined,
    banner?: Blob | undefined
  ): Promise<GetProfileResponse> => {
    try {
      if (profilePicture) {
        const storage = firebase.storage();
        const userID = firebase.auth().currentUser.uid;
        storage
          .ref(`/${userID.toLowerCase()}/profile/profile_picture`)
          .put(profilePicture);
      }

      if (banner) {
        const storage = firebase.storage();
        const userID = firebase.auth().currentUser.uid;
        storage.ref(`/${userID.toLowerCase()}/profile/banner`).put(banner);
      }

      const auth = await this.getPostAuthHeader();

      const instance = this.init();
      instance.interceptors.response.use(
        (response) => {
          return response.data;
        },
        (error) => {
          return error;
        }
      );

      return await instance.post(
        `/createProfile`,
        {
          userID: firebase.auth().currentUser.uid,
          name: name,
          username: username,
          email: firebase.auth().currentUser.email,
          address: address,
          description: "", //TODO: add this later
          links: links,
        },
        { headers: auth }
      );
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  updateProfile = async (
    name: string,
    username: string,
    links: SocialMediaLink[]
  ) => {
    try {
      const instance = this.init();
      const auth = await this.getPostAuthHeader();

      return instance.post(
        `/updateProfile`,
        {
          user_id: firebase.auth().currentUser.uid,
          name: name,
          username: username,
          links: links,
        },
        { headers: auth }
      );
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  updateUser = async (
    update: UpdateUserRequest,
    profilePic: string | undefined,
    banner: string | undefined
  ): Promise<any> => {
    try {
      const userId = firebase.auth().currentUser.uid;

      if (profilePic) {
        const profilePicBlob = await fetch(profilePic).then((r) => r.blob());
        await this.updateProfilePicture(profilePicBlob);
      }

      if (banner) {
        const bannerBlob = await fetch(banner).then((r) => r.blob());
        await this.updateProfileBanner(bannerBlob);
      }

      const instance = this.init();

      const auth = await this.getPostAuthHeader();

      return await instance.post(
        `/updateUser`,
        {
          user_id: userId,
          request: update,
        },
        { headers: auth }
      );
    } catch (error) {
      return error;
    }
  };

  updateProfilePicture = async (profilePicture: Blob | undefined) => {
    try {
      const storage = firebase.storage();
      const userID = firebase.auth().currentUser.uid;

      return storage
        .ref(`/${userID.toLowerCase()}/profile/profile_picture`)
        .put(profilePicture);
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  updateProfileBanner = async (banner: Blob | undefined) => {
    try {
      const storage = firebase.storage();
      const userID = firebase.auth().currentUser.uid;

      return storage.ref(`/${userID.toLowerCase()}/profile/banner`).put(banner);
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  getProfileBannerUrl = async (userId) => {
    const storage = firebase.storage();
    return storage
      .ref(`/${userId.toLowerCase()}/profile/banner`)
      .getDownloadURL();
  };

  getProfilePicUrl = async (userId) => {
    const storage = firebase.storage();
    return storage
      .ref(`/${userId.toLowerCase()}/profile/profile_picture`)
      .getDownloadURL();
  };

  //id is in format: [ALBUM_NAME]-$[TOKEN_SYMBOL]
  getAlbumBannerUrl = async (albumName, ownerId) => {
    const storage = firebase.storage();
    return storage
      .ref(`/${ownerId.toLowerCase()}/albums/${albumName}/banner`)
      .getDownloadURL();
  };

  updateAlbum = async (albumName: string, description: string) => {
    try {
      const instance = this.init();
      const auth = await this.getPostAuthHeader();

      return instance.post(
        `/updateAlbumDetails`,
        {
          user_id: firebase.auth().currentUser.uid,
          name: albumName,
          description: description,
        },
        { headers: auth }
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateAlbumBanner = async (albumName: string, banner: Blob | undefined) => {
    try {
      const storage = firebase.storage();
      const userID = firebase.auth().currentUser.uid;

      return storage
        .ref(`/${userID.toLowerCase()}/albums/${albumName}/banner`)
        .put(banner);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //Check username if already in use
  checkUsername = async (username) => {
    const instance = this.init();

    instance.interceptors.response.use(
      (response) => {
        return response.data.available;
      },
      (error) => {
        return error;
      }
    );

    return await instance.post(`/checkUsername`, {
      username: username,
    });
  };

  walletVerificationStatus = async (userId, address) => {
    const instance = this.init();

    instance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return error;
      }
    );

    const auth = await this.getPostAuthHeader();

    return await instance.post(
      `/getVerificationStatus`,
      {
        user: {
          user_id: userId,
        },
        address: address,
      },
      { headers: auth }
    );
  };

  verifySignature = async (userId, address, signature) => {
    const instance = this.init();

    instance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return error;
      }
    );

    const auth = await this.getPostAuthHeader();

    return await instance.post(
      `/verifySignature`,
      {
        user: {
          user_id: userId,
        },
        address: address,
        signature: signature,
      },
      { headers: auth }
    );
  };

  createFundDetails = async (
    userId: string,
    albumName: string,
    nfts: NFTExport[],
    description: string,
    tokenName: string,
    snapshotHash: string,
    tx: string,
    bannerUrl?: string | undefined
  ) => {
    try {
      const instance = this.init();
      const auth = await this.getPostAuthHeader();

      if (bannerUrl) {
        const storage = firebase.storage();
        const bannerBlob = await fetch(bannerUrl).then((r) => r.blob());
        storage
          .ref(`/${userId.toLowerCase()}/albums/${albumName}/banner`)
          .put(bannerBlob);
      }

      return await instance.post(
        `/createAlbumDetails`,
        {
          user_id: userId,
          name: albumName,
          nfts: nfts,
          description: description,
          tokenName: tokenName,
          snapshotHash: snapshotHash,
          tx: tx,
        },
        { headers: auth }
      );
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  checkAlbumName = async (albumName) => {
    const instance = this.init();

    return await instance.post(`/checkAlbumName`, {
      name: albumName,
    });
  };

  checkTokenName = async (tokenName) => {
    const instance = this.init();

    return await instance.post(`/checkTokenName`, {
      name: tokenName,
    });
  };

  //Checks both album and token names
  checkAlbumDetails = async (albumName, tokenName) => {
    try {
      const albumResponse = await this.checkAlbumName(albumName);
      if (!albumResponse.data.available) {
        return {
          available: false,
          message: `album name ${albumName} already taken`,
        };
      }

      const tokenResponse = await this.checkTokenName(tokenName);
      if (!tokenResponse.data.available) {
        return {
          available: false,
          message: `token name ${tokenName} already taken`,
        };
      }

      return {
        available: true,
        message: "",
      };
    } catch (error) {
      throw error;
    }
  };

  createSnapshotHash = async (albumName: string, tokenName: string) => {
    try {
      const instance = this.init();
      const auth = await this.getPostAuthHeader();

      return await instance.post(
        `/generateSnapshotHash`,
        {
          albumName: albumName,
          tokenName: tokenName,
        },
        {}
      );
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // getProposalVotes = async (proposalId: string): Promise<Map<string, number>> => {
  //   try {

  //     //Get choices for the proposal
  //     const choices = await this.getProposalChoices(proposalId);
  //     let choiceMap = new Map<string, number>();
  //     choices.forEach(choice => {
  //       choiceMap.set(choice.toLowerCase(), 0);
  //     });

  //     const instance = this.init();

  //     const query = `
  //       query Votes {
  //         votes (
  //           first: 5000
  //           skip: 0
  //           where: {
  //             proposal: "${proposalId}"
  //           }
  //           orderBy: "created",
  //           orderDirection: desc
  //         ) {
  //           voter
  //           created
  //           choice
  //         }
  //       }
  //     `;

  //     const config = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json',
  //       },
  //     }

  //     const response = await instance.post(`https://hub.snapshot.page/graphql`, { query }, config);
  //     const votes: { "voter": string, "created": number, "choice": number }[] = response.data.data.votes;

  //     votes.forEach(vote => {
  //       const index = vote.choice - 1; //the vote choices start at 1 instead of 0;
  //       const choiceText = choices[index].toLowerCase();

  //       choiceMap.set(choiceText, choiceMap.get(choiceText) + 1);
  //     });

  //     return choiceMap;
  //   } catch (err) {
  //     console.log(err);
  //     throw err;
  //   }
  // }

  isOnWhitelist = async (userID: string) => {
    try {
      const instance = this.init();

      return await instance.post(`/whitelist`, { userID: userID });
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  getProposalChoices = async (proposalId: string): Promise<string[]> => {
    try {
      const instance = this.init();

      const query = `
        query Proposal {
          proposal(id:"${proposalId}") {
            choices
          }
        }
      `;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await instance.post(
        `https://hub.snapshot.page/graphql`,
        { query },
        config
      );
      return response.data.data.proposal.choices;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  getAlbumTokenOwnership = async (tokenAddress: string) => {
    try {
      const instance = this.init();
      return instance.get(`/album_ownership/${tokenAddress}`);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  getComingSoon = async () => {
    try {
      const instance = this.init();
      return instance.get(`/coming_soon`);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  getAlbumNftImages = async (albumName: string) => {
    try {
      // const instance = this.init();
      // return instance.get(`/album_nft_images/${albumName}`);
      let random = Math.floor(Math.random() * (5 - 0) + 0);
      const images = [
        "https://placeimg.com/1000/1000/animals",
        "https://placeimg.com/200/200/arch",
        "https://placeimg.com/300/350/nature",
        "https://placeimg.com/600/900/tech",
        "https://placeimg.com/600/700/people",
      ];

      let randomImages = images.slice(0, random + 1);

      return {
        data: randomImages,
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  //Farm Api Calls

  getAllFarmData = async (): Promise<any> => {
    try {
      return this.init().post(`/getPools`);
    } catch (err) {
      console.log("getAllFarmData ERROR: ", err);
      throw err;
    }
  };

  getMyRewards = async (address: string) => {
    try {
      return this.init().post(`/getMyRewards`, {
        address: address,
      });
    } catch (err) {
      console.log("getMyRewards ERROR: ", err);
      throw err;
    }
  };

  getUserInfoForPool = async (address: string, poolID: number) => {
    try {
      return this.init().post(`/getUserInfoForPool`, {
        address: address,
        id: poolID,
      });
    } catch (err) {
      console.log("getPoolRewards ERROR: ", err);
      throw err;
    }
  };

  getPoolDetails = async (poolID) => {
    try {
      return this.init().post(`/getPool`, {
        id: poolID,
      });
    } catch (err) {
      console.log("getPool ERROR: ", err);
      throw err;
    }
  };

  // User Calls

  getUser = async (address): Promise<User> => {
    const instance = this.init();
    instance.interceptors.response.use(
      (response) => {
        return response.data.user;
      },
      (error) => {
        return error;
      }
    );

    return instance.post(`/getUser`, {
      address: address,
    });
  };

  getProfile = async (userID): Promise<User> => {
    const instance = this.init();
    instance.interceptors.response.use(
      (response) => {
        return response.data.user;
      },
      (error) => {
        return error;
      }
    );

    return instance.post(`/getProfile`, {
      id: userID,
    });
  };

  authenticate = async (address, signature): Promise<any> => {
    return this.init().post(`/authenticate`, {
      address: address,
      signature: signature,
    });
  };

  //Notifications
  //TODO: update with actual API call, Linear Ticket - SZN-632
  getMessages = async () => {
    const auth = await this.getPostAuthHeader();
    return this.init().post(`/getMessages`, {}, { headers: auth });
    // const date1 = new Date();
    // const date2 = new Date();
    // const date3 = new Date();

    // return {
    //   messages: [
    //     { id: 0, unread: false, title: "BUYOUT PROPOSED", message: "NFDeez", createdAt: (new Date()) },
    //     { id: 1, unread: false, title: "BUYOUT PROPOSED", message: "The Meebits Album", createdAt: date1.setTime(date1.getTime() - (2 * 60 * 60 * 1000)) },
    //     { id: 2, unread: true, title: "BUYOUT PROPOSED", message: "CryptoPunk Album", createdAt: date2.setTime(date2.getTime() - (3 * 60 * 60 * 1000)) },
    //     { id: 3, unread: true, title: "BUYOUT PROPOSED", message: "Mr. Misang Album", createdAt: date3.setTime(date3.getTime() - (4 * 60 * 60 * 1000)) }
    //   ]
    // };
  };

  getMessagesPreview = async () => {
    const auth = await this.getPostAuthHeader();
    return this.init().post(`/getMessagesPreview`, {}, { headers: auth });
    // const date1 = new Date();
    // const date2 = new Date();
    // const date3 = new Date();

    // return {
    //   messages: [
    //     { id: 0, unread: false, title: "BUYOUT PROPOSED", message: "NFDeez", createdAt: (new Date()) },
    //     { id: 1, unread: false, title: "BUYOUT PROPOSED", message: "The Meebits Album", createdAt: date1.setTime(date1.getTime() - (2 * 60 * 60 * 1000)) },
    //     { id: 2, unread: true, title: "BUYOUT PROPOSED", message: "CryptoPunk Album", createdAt: date2.setTime(date2.getTime() - (3 * 60 * 60 * 1000)) },
    //     { id: 3, unread: true, title: "BUYOUT PROPOSED", message: "Mr. Misang Album", createdAt: date3.setTime(date3.getTime() - (4 * 60 * 60 * 1000)) }
    //   ]
    // };
  };

  hasUnreadMessages = async () => {
    const auth = await this.getPostAuthHeader();
    return this.init().post(`/hasUnreadMessages`, {}, { headers: auth });
  };

  markRead = async (messageID: string) => {
    const auth = await this.getPostAuthHeader();
    return this.init().post(
      `/markRead`,
      {
        messageID: messageID,
      },
      { headers: auth }
    );
  };

  markAllRead = async () => {
    const auth = await this.getPostAuthHeader();
    return this.init().post(`/markAllRead`, {}, { headers: auth });
  };

  // Newsletter
  subscribeNewsletter = async (email: string): Promise<any> => {
    return this.init().post(`/subscribe`, {
      email,
    });
  };

  // Profile

  getAlbumsOwned = async (address: string): Promise<any> => {
    return this.init().post(`/getAlbumsOwned`, {
      address,
    });
  };

  getAlbumsCreated = async (address: string): Promise<any> => {
    return this.init().post(`/getAlbumsCurated`, {
      address,
    });
  };

  updateAddNftCreated = async (albumName: string) => {
    const auth = await this.getPostAuthHeader();

    return this.init().post(
      `/updateAlbumState`,
      {
        user_id: firebase.auth().currentUser,
        name: albumName,
        updateRequest: {
          name: albumName,
          state: {
            archived: null,
            addnft: null,
            pending: null,
            tokenSale: null,
          },
        },
      },
      { headers: auth }
    );
  };

  // snapshot
  getProposals = async (albumName: string, skip: number) => {
    return this.init().post("/getAlbumProposals", {
      albumName: albumName,
      skip: skip,
    });
  };

  getProposalVote = async (proposalHash: string, tokenAddress: string) => {
    return this.init().post(
      `/getAlbumBuyoutScores`,
      {
        proposalHash: proposalHash,
        tokenAddress: tokenAddress,
      },
      {}
    );
  };

  createSellOrder = async (
    orderHash: string,
    order: Order,
    proposalId: string
  ) => {
    const auth = await this.getPostAuthHeader();

    return this.init().post(
      `/createSellOrder`,
      {
        orderHash: orderHash,
        order: JSON.stringify(order),
        proposalId: proposalId,
      },
      { headers: auth }
    );
  };

  getQualifiedNFTsForAlbum = async (albumId: string, userAddress?: string) => {
    return this.init().post(
      `/getQualifiedNFTsForAlbum`,
      {
        albumID: albumId,
        address: userAddress,
      },
      {}
    );
  };

  getBountiesForAlbum = async (albumId: string) => {
    return this.init().post(
      `/getBountiesForAlbum`,
      {
        albumID: albumId,
      },
      {}
    );
  };

  getQualifiedBountiesForAlbum = async (
    albumId: string,
    walletAddress: string
  ) => {
    return this.init().post(
      `/getQualifiedBountiesForAlbum`,
      {
        albumID: albumId,
        walletAddress: walletAddress,
      },
      {}
    );
  };

  hasBounties = async (albumId: string) => {
    return this.init().post(
      `/hasBounties`,
      {
        albumID: albumId,
      },
      {}
    );
  };

  getNFTsForCollection = async (
    userAddress: string,
    contractAddress: string,
    cursor: string = ""
  ): Promise<AxiosResponse<any>> => {
    const instance = this.init();
    // TODO: Fix Auth header
    //const auth = await this.getPostAuthHeader();
    return instance.post(
      `/getNFTsForCollection`,
      {
        address: userAddress,
        contract: contractAddress,
        cursor: cursor,
      },
      //{ headers: auth }
      {}
    );
  };

  getNFTsForContract = async (
    tokenIds: string[],
    contractAddress: string,
    offset: number = 0
  ) => {
    const instance = this.init();
    // TODO: Fix Auth header?
    //const auth = await this.getPostAuthHeader();
    return instance.post(
      `/getNFTsForContract`,
      {
        tokenIds: tokenIds,
        contract: contractAddress,
        offset: offset,
      },
      //{ headers: auth }
      {}
    );
  };

  getContractMetadata = async (address: string) => {
    return this.init().post(
      `/getContractMetadata`,
      {
        address: address,
      },
      {}
    );
  };

  cacheValidators = async (
    validators: BountyValidatorExport[]
  ): Promise<any> => {
    const auth = await this.getPostAuthHeader();
    console.log(validators);

    return this.init().post(
      `/cacheValidators`,
      {
        validator: validators,
      },
      {
        headers: auth,
      }
    );
  };

  getWyvernProxy = async (address: string): Promise<AxiosResponse<any>> => {
    const auth = await this.getPostAuthHeader();

    return this.init().post(
      `/getWyvernProxy`,
      {
        address: address,
      },
      {
        headers: auth,
      }
    );
  };

  getApproveERC20TransferData = async (
    to: string
  ): Promise<AxiosResponse<any>> => {
    const auth = await this.getPostAuthHeader();

    return this.init().post(
      `/getApproveERC20TransferData`,
      {
        to: to,
      },
      {
        headers: auth,
      }
    );
  };

  getApproveERC721TransferData = async (
    to: string
  ): Promise<AxiosResponse<any>> => {
    const auth = await this.getPostAuthHeader();

    return this.init().post(
      `/getApproveERC721TransferData`,
      {
        to: to,
      },
      {
        headers: auth,
      }
    );
  };

  getApproveERC20ToWyvernTokenProxy = async (): Promise<AxiosResponse<any>> => {
    const auth = await this.getPostAuthHeader();

    return this.init().post(
      `/getApproveERC20ToWyvernTokenProxy`,
      {},
      {
        headers: auth,
      }
    );
  };

  getOpenSeaSellOrderData = async (
    nftOwner: string,
    selectedNFT: NFTExport,
    listPrice: string,
    startListDate: string,
    endListDate: string
  ): Promise<AxiosResponse<GetOpenSeaSellOrderDataResponse>> => {
    const auth = await this.getPostAuthHeader();

    return this.init().post(
      `/getOpenSeaSellOrderData`,
      {
        nftOwner: nftOwner,
        selectedNFT,
        listPrice,
        startListDate,
        endListDate,
      },
      {
        headers: auth,
      }
    );
  };

  getWalletBalances = async (
    walletAddress: string
  ): Promise<AxiosResponse<any>> => {
    const auth = await this.getPostAuthHeader();

    return this.init().post(
      `/getWalletBalances`,
      {
        walletAddress: walletAddress,
      },
      {
        headers: auth,
      }
    );
  };
}
