import React from "react";
import MainLogo from "../logo/MainLogo";
import MenuNav from "../menu/newMenuNav";
import MobileLogo from "../common/logo/mobileLogo";

const Header = () => {
  return (
    <div
      className="h-full flex flex-col flex-grow w-full bg-dark-background"
      style={{ height: "40px" }}
    >
      <div className="flex flex-row flex-grow w-full items-center">
        <div className="flex flex-row flex-grow items-center">
          <div
            className="hidden md:block"
            style={{ width: "136.67px", height: "40px" }}
          >
            <MainLogo />
          </div>
          <div className="flex md:hidden">
            <MobileLogo />
          </div>
        </div>
        <MenuNav />
      </div>
    </div>
  );
};

export default Header;
