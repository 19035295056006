import { useEthers } from "@usedapp/core";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useCallback } from "react";

export const useDisconnectWallet = (): (() => Promise<void>) => {
  const { connector } = useEthers();

  const disconnect = useCallback(async (): Promise<void> => {
    if (connector) {
      //killSession() kills the session between our app and wallet connect
      if (connector instanceof WalletConnectConnector) {
        //We kill the session so the user can connect with other wallets in the future
        const provider: WalletConnectProvider = connector.walletConnectProvider;
        await provider.wc.killSession();
      } else {
        //Otherwise we jsut deactivate the web browser wallet
        //Don't use connector because it kills the readonly web3 provider
        connector.deactivate();
      }
    }
  }, [connector]);

  return disconnect;
};
