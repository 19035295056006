import React, { FC } from "react";
import CSS from "csstype";

import "./button.css";

type ButtonProps = {
  onClick?: any;
  fill?: boolean;
  size?: "sm" | "md" | "lg";
  disabled?: boolean;
  width?: number | "full";
  shadow?: boolean;
};

const Button: FC<ButtonProps> = ({
  onClick,
  children,
  fill = true,
  size = "lg",
  disabled = false,
  width,
  shadow = false,
}) => {
  //Dyanamic styles for the button
  const styles: CSS.Properties = {};

  //Dynamic styles for content/children components
  const contentStyles: CSS.Properties = {};

  //To apply fonts easier prebuilt css classes
  let classes: string[] = [];

  if (size === "sm") {
    styles.height = "40px";
    styles.width = "max-content";

    contentStyles.padding = "8px 16px";

    classes.push("btn-second");
  } else if (size === "md") {
    styles.height = "40px";
    styles.width = "max-content";

    contentStyles.padding = "8px 24px";

    classes.push("btn-second");
  } else {
    styles.height = "48px";
    styles.width = "max-content";

    contentStyles.padding = "12px 24px";

    classes.push("btn-primary");
  }

  if (fill) {
    classes.push(`text-black `);
  } else {
    classes.push(`text-dark-red`);
    classes.push("no-fill");
  }

  if (width) {
    if (width === "full") {
      styles.width = "100%";
    } else {
      styles.width = `${width}px`;
    }
  }

  if (shadow) {
    styles.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.25)";
  }

  //Build the class string
  const className = classes.join(" ");

  return (
    <button
      className={`${className} button-base text-center disabled:opacity-50 disabled:cursor-default focus:ring-transparent focus:outline-none`}
      style={disabled ? { pointerEvents: "none", ...styles } : { ...styles }}
      disabled={disabled}
      onClick={onClick}
    >
      <div style={contentStyles} className="flex flex-row justify-center">
        {children}
      </div>
    </button>
  );
};

export default Button;
