import { Dialog } from "@headlessui/react";
import React, { FC } from "react";

interface ModalTitleProps {}

const ModalTitle: FC<ModalTitleProps> = ({ children }) => {
  return (
    <Dialog.Title
      as="div"
      className="flex flex-row justify-between text-white align-middle items-center"
    >
      {children}
    </Dialog.Title>
  );
};

export default ModalTitle;
