import React, { FC } from "react";
import { CircularProgress } from "@mui/material";

interface LoadingProps {
  size?: number;
  color?: string;
}

const Loading: FC<LoadingProps> = ({
  size = 40,
  color = "rgba(255, 79, 99, 1)",
}) => {
  // const classes = useStyles();

  return (
    <CircularProgress
      style={{ width: `${size}px`, height: `${size}px`, color: `${color}` }}
    />
  );
};

export default Loading;
