import React, { FC } from "react";

interface InputProps {
  label?: string;
  subtitle?: string;
  error?: string;
  width?: number;
}

//Generic input component to display labels and error messages
const Input: FC<InputProps> = ({ label, subtitle, error, width, children }) => {
  const widthStyle = width ? `${width}px` : "100%";

  return (
    <div
      className="flex flex-col space-y-4 text-white"
      style={{ width: widthStyle }}
    >
      <label className="caption">{label}</label>
      {subtitle && <p className="body1 text-dark-white-64 pb-4">{subtitle}</p>}
      <div className="mt-4 w-full">{children}</div>
      <p className="body1 text-primary-red break-words">{error}</p>
    </div>
  );
};

export default Input;
