import React, { FC, useState, useEffect } from "react";
import { Link } from "gatsby";
import Api from "../../services/api";
import { buildNotificationLink } from "../../utils/utils";
import { Message } from "../../models/messages";
import { DateTime } from "luxon";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import CloseIcon from "@mui/icons-material/Close";

interface NotificationModalMessageProps {
  hideModal: () => void;
  hasUnread: boolean;
}

const NotificationModalMessages: FC<NotificationModalMessageProps> = ({
  hideModal,
  hasUnread,
}) => {
  const [notifications, setNotifications] = useState<Message[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [markRead, setMarkRead] = useState<boolean>(false);

  useEffect(() => {
    getMessages();
  }, []);

  useEffect(() => {
    if (markRead) {
      getMessages();
      setMarkRead(false);
    }
  }, [markRead]);

  const getMessages = () => {
    const api = new Api();
    api
      .getMessagesPreview()
      .then((response) => {
        setNotifications(response.data.messages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setMarkRead(false);
      });
  };

  const calcDuration = (date) => {
    let createdDate = DateTime.fromISO(date);
    let now = DateTime.now();
    let luxDuration = now.diff(createdDate, [
      "years",
      "months",
      "days",
      "hours",
      "minutes",
    ]);
    let { years, months, days, hours, minutes } = luxDuration.values;

    let durationString = "";
    if (years > 0) {
      if (years === 1) {
        durationString += `${years} year `;
      } else {
        durationString += `${years} years `;
      }
    }
    if (months > 0) {
      if (months === 1) {
        durationString += `${months} month `;
      } else {
        durationString += `${months} months `;
      }
    }
    if (days > 0) {
      if (days === 1) {
        durationString += `${days} day `;
      } else {
        durationString += `${days} days `;
      }
    }
    if (hours > 0) {
      if (hours === 1) {
        durationString += `${hours} hour ago`;
      } else {
        durationString += `${hours} hours ago`;
      }
    } else {
      durationString = "Less than 1 hour ago";
    }

    return durationString;
  };

  const markMessageRead = (messageId) => {
    const api = new Api();
    api
      .markRead(messageId)
      .then((resp) => {
        setMarkRead(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const markAllRead = () => {
    const api = new Api();
    api
      .markAllRead()
      .then((resp) => {
        setMarkRead(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (loading) {
    return null;
  }

  return (
    <div
      className="absolute bg-dark-notification-modal flex flex-col w-328 lg:w-375 top-44 -right-96 md:top-60 md:-right-170"
      onBlur={() => hideModal}
      style={{
        borderRadius: "4px",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
        zIndex: 100,
        maxHeight: "389px",
      }}
    >
      <div className="flex flex-row px-16 pt-16 space-x-32">
        <div className="flex flex-grow text-primary-light-grey">
          <h1>Notifications</h1>
        </div>
        {notifications && notifications.length > 0 && (
          <button
            className={`btn-second ${
              !hasUnread ? "disabled-link opacity-50" : ""
            }`}
            onClick={markAllRead}
            disabled={!hasUnread}
          >
            <ClearAllIcon className="fill-current text-dark-light-grey" />
          </button>
        )}
        <button onClick={hideModal}>
          <CloseIcon className="fill-current text-dark-light-grey" />
        </button>
      </div>

      <div
        className="flex flex-row bg-black my-16 mx-16"
        style={{ height: "1px" }}
      />
      {/* Notification Cards */}
      <div className="flex flex-col overflow-y-auto no-scrollbar">
        {notifications && notifications.length > 0 ? (
          <>
            {notifications.map((notice) =>
              notice.destination?.snapshot ? (
                <a
                  href={buildNotificationLink(notice.type, notice.destination)}
                  target="_blank"
                  onClick={() => markMessageRead(notice.id)}
                  onMouseOver={() => markMessageRead(notice.id)}
                  className="flex flex-row px-16 hover:bg-dark-background"
                  style={{ borderRadius: "4px" }}
                  key={`nm-${notice.id}`}
                >
                  <div className="flex-flex-col flex-grow space-y-4 pt-8">
                    <p
                      className="caption text-primary-light-grey"
                      style={{ wordBreak: "normal", overflowWrap: "anywhere" }}
                    >
                      {notice.title}
                    </p>
                    <div className="flex flex-row">
                      <div className="flex flex-grow">
                        <p
                          className="body1 text-dark-white-64"
                          style={{
                            wordBreak: "normal",
                            overflowWrap: "anywhere",
                          }}
                        >
                          {notice.message}
                        </p>
                      </div>
                      {notice.unread ? (
                        <div className="flex-flex-col ml-8 self-center">
                          <div className="rounded-full h-12 w-12 horiz-gradient" />
                        </div>
                      ) : null}
                    </div>
                    <p className="body1 text-dark-white-64 pb-12">
                      {calcDuration(notice.createdAt)}
                    </p>
                  </div>
                </a>
              ) : (
                <Link
                  to={buildNotificationLink(notice.type, notice.destination)}
                  onClick={() => markMessageRead(notice.id)}
                  onMouseOver={() => markMessageRead(notice.id)}
                  className="flex flex-row px-16 hover:bg-dark-background"
                  style={{ borderRadius: "4px" }}
                  key={`nm-${notice.id}`}
                >
                  <div className="flex-flex-col flex-grow space-y-4 pt-8">
                    <p
                      className="caption text-primary-light-grey"
                      style={{ wordBreak: "normal", overflowWrap: "anywhere" }}
                    >
                      {notice.title}
                    </p>
                    <div className="flex flex-row">
                      <div className="flex flex-grow">
                        <p
                          className="body1 text-dark-white-64"
                          style={{
                            wordBreak: "normal",
                            overflowWrap: "anywhere",
                          }}
                        >
                          {notice.message}
                        </p>
                      </div>
                      {notice.unread ? (
                        <div className="flex-flex-col ml-8 self-center">
                          <div className="rounded-full h-12 w-12 horiz-gradient" />
                        </div>
                      ) : null}
                    </div>
                    <p className="body1 text-dark-white-64 pb-12">
                      {calcDuration(notice.createdAt)}
                    </p>
                  </div>
                </Link>
              )
            )}
          </>
        ) : (
          <div className="flex flex-row px-16">
            <p className="caption text-primary-light-grey mt-8 mb-32">
              No notifications available
            </p>
          </div>
        )}
      </div>
      {notifications?.length > 0 && (
        <div className="flex flex-row pb-24 pt-4 px-16">
          <Link to="/notifications" className="text-dark-red btn-second">
            View all
          </Link>
        </div>
      )}
    </div>
  );
};

export default NotificationModalMessages;
