import { useEthers } from "@usedapp/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { utils } from "ethers";
import { useCallback } from "react";

//Returns callback to sign a message
export const useSignMsg = (): ((
  message: string
) => Promise<string | undefined>) => {
  const { account, library, connector } = useEthers();

  const signMsg = useCallback(
    async (message: string): Promise<string | undefined> => {
      if (library && connector) {
        let signature: string;

        //If WalletConnect, we have to do some workarounds for the nonce sign
        //Reference: https://github.com/WalletConnect/walletconnect-monorepo/issues/347#issuecomment-880553018
        if (connector instanceof WalletConnectConnector) {
          const messageBytes = utils.toUtf8Bytes(message);
          signature = await library
            .getSigner()
            .provider.send("personal_sign", [
              utils.hexlify(messageBytes),
              account.toLowerCase(),
            ]);
        } else {
          signature = await library.getSigner(0).signMessage(message);
        }

        return signature;
      } else {
        return undefined;
      }
    },
    [account, library, connector]
  );

  return signMsg;
};
